<template>
    <div class="zsCheckInContainer">
        <van-nav-bar title="注塑登记" left-text left-arrow @click-left="onClickLeft" :fixed="true" />
        <div 
            class="content" 
            :style="showBtn?'padding-bottom:1.06667rem;':'padding-bottom:0rem;'"
        >
            <van-form>
                <div class="peField">
                    <van-field 
                        disabled 
                        required 
                        right-icon="edit" 
                        v-model="form.empname" 
                        label="姓名" 
                        placeholder="请输入姓名" 
                    />
                    <van-field 
                        :disabled="formDisable"
                        clickable 
                        readonly 
                        required 
                        right-icon="arrow-down" 
                        v-model="form.bd" 
                        placeholder="请选择开始日期"
                        label="开始日期" 
                        @click="handShowBdPicker"
                    />
                    <van-field 
                        :disabled="formDisable"
                        clickable 
                        readonly 
                        required 
                        right-icon="arrow-down" 
                        v-model="form.ed" 
                        placeholder="请选择结束日期"
                        label="结束日期" 
                        @click="handShowEdPicker"
                    />
                    <van-field 
                        disabled 
                        right-icon="edit" 
                        v-model="form.scd" 
                        label="生产地" 
                        placeholder="请输入生成地" 
                    />
                    <van-field 
                        disabled 
                        right-icon="edit" 
                        v-model="form.bb" 
                        label="班别" 
                        placeholder="请输入班别" 
                    />
                    <van-field 
                        :disabled="formDisable"
                        clickable 
                        readonly 
                        required 
                        right-icon="arrow-down" 
                        v-model="form.worktype"
                        placeholder="请选择工作方式" 
                        label="工作方式" 
                        @click="getWorksWay"
                    />
                    <van-field 
                        :disabled="formDisable"
                        clickable 
                        readonly 
                        required 
                        right-icon="arrow-down" 
                        v-model="form.jjscl"
                        placeholder="请选择胶件生产类" 
                        label="胶件生产类" 
                        @click="getJjscl"
                    />
                </div>
            </van-form>
            <div v-if="dataData.length > 0 && newAutoid != 0" class="contentMainTable" style="padding-top: 15px; padding-bottom: 15px">
                <div style="width: 100%; overflow: auto">
                    <table style="border-collapse: collapse;width: 1430px;">
                        <tr style="background: #e4e9f3; height: 40px;border-bottom: 1px solid #ccc;">
                            <th colspan="2" style="border-right: 1px solid #ccc">排产日期资料</th>
                            <th style="border-right: 1px solid #ccc">机型资料</th>
                            <th colspan="2" style="border-right: 1px solid #ccc">型号、订单资料</th>
                            <th colspan="4" style="border-right: 1px solid #ccc">模具、部件资料</th>
                            <th style="border-right: 1px solid #ccc">材料资料</th>
                            <th colspan="4" >需生产资料</th>
                        </tr>
                        <tr style="background: #e4e9f3; height: 40px">
                            <th style="border-right: 1px solid #ccc;width: 50px;">序号</th>
                            <th style="border-right: 1px solid #ccc">物料上机时间</th>
                            <th style="border-right: 1px solid #ccc">实际机台</th>
                            <th style="border-right: 1px solid #ccc">型号</th>
                            <th style="border-right: 1px solid #ccc">订单系统编号（标签）</th>
                            <th style="border-right: 1px solid #ccc">模具编号</th>
                            <th style="border-right: 1px solid #ccc">部件名称</th>
                            <th style="border-right: 1px solid #ccc">零件规格</th>
                            <th style="border-right: 1px solid #ccc">用量/个</th>
                            <th style="border-right: 1px solid #ccc">材料材质</th>
                            <th style="border-right: 1px solid #ccc">生产数量/台</th>
                            <th style="border-right: 1px solid #ccc">未完成/件</th>
                            <th style="border-right: 1px solid #ccc">未完成/台</th>
                            <th>录入数量</th>
                        </tr>
                        <tr v-for="(value, key) in dataData" :key="key">
                            <td style="border-right: 1px solid #ccc">
                                <div style="text-align: center; font-size: 14px;">
                                    {{ key+1 }}
                                </div>
                            </td>
                            <td style="border-right: 1px solid #ccc">
                                <div style="text-align: center; font-size: 14px">
                                    {{ value.wlsj_date }}
                                </div>
                            </td>
                            <td style="border-right: 1px solid #ccc">
                                <div style="text-align: center; font-size: 14px">
                                    {{ value.machinename }}
                                </div>
                            </td>
                            <td style="border-right: 1px solid #ccc">
                                <div style="text-align: center; font-size: 14px">
                                    {{ value.stylename }}
                                </div>
                            </td>
                            <td style="border-right: 1px solid #ccc">
                                <div style="text-align: center; font-size: 14px">
                                    {{ value.syscode }}
                                </div>
                            </td>
                            <td style="border-right: 1px solid #ccc">
                                <div style="text-align: center; font-size: 14px">
                                    {{ value.mjcode }}
                                </div>
                            </td>
                            <td style="border-right: 1px solid #ccc">
                                <div style="text-align: center; font-size: 14px">
                                    {{ value.bjname }}
                                </div>
                            </td>
                            <td style="border-right: 1px solid #ccc">
                                <div style="text-align: center; font-size: 14px">
                                    {{ value.specname }}
                                </div>
                            </td>
                            <td style="border-right: 1px solid #ccc">
                                <div style="text-align: center; font-size: 14px">
                                    {{ value.usenum }}
                                </div>
                            </td>
                            <td style="border-right: 1px solid #ccc">
                                <div style="text-align: center; font-size: 14px">
                                    {{ value.czcl }}
                                </div>
                            </td>
                            <td style="border-right: 1px solid #ccc">
                                <div style="text-align: center; font-size: 14px">
                                    {{ value.scnum }}
                                </div>
                            </td>
                            <td style="border-right: 1px solid #ccc">
                                <div style="text-align: center; font-size: 14px">
                                    {{ value.not_jnum }}
                                </div>
                            </td>
                            <td style="border-right: 1px solid #ccc">
                                <div style="text-align: center; font-size: 14px">
                                    {{ value.not_tnum }}
                                </div>
                            </td>
                            <td style="border-right: 1px solid #ccc">
                                <div style="text-align: center; font-size: 14px">
                                    <input 
                                        v-model="value.inputnum"
                                        style="width: 80px; height: 33px"
                                        type="text"
                                        class="h5Input" 
                                        :readonly="formDisable"
                                    />
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <div class="footerBox" v-if="showBtn">
            <div class="flexBox" v-if="!showCheckBtn">
                <van-button :disabled="canSave"  @click="stagingFun" type="primary" style="width: 45%;height: 100%;">暂存</van-button>
                <van-button :disabled="newAutoid == 0" @click="submitFun" type="info" style="width: 45%;height: 100%;">提交</van-button>
            </div>
            <div class="flexBox" v-if="showCheckBtn">
                <van-button @click="agreeFun" type="primary" style="width: 45%;height: 100%;">同意</van-button>
                <van-button @click="notAgreeFun" type="info" style="width: 45%;height: 100%;">不同意</van-button>
            </div>
        </div>
        <!-- 选择开始日期 -->
        <van-calendar
            v-model="showBdPicker"
            :min-date="minDate"
            :max-date="maxDate"
            :default-date="bdDefault"
            @confirm="onBdConfirm"
            
        />
        <!-- 选择结束日期 -->
        <van-calendar
            v-model="showEdPicker"
            :min-date="minDate"
            :max-date="maxDate"
            :default-date="edDefault"
            @confirm="onEdConfirm"
        />
        <!-- 选择工作方式 -->
        <van-popup 
            v-model="showWorkTypePicker"
            round
            position="bottom"
        >
            <van-picker 
                show-toolbar
                :columns="worksWayData"
                :confirm-button-text="$t('module.confirm')"
                :cancel-button-text="$t('module.cancel')"
                :default-index="worksWayDefault"
                @cancel="showWorkTypePicker = false"
                @confirm="onConfirmWorksWay" 
            />
        </van-popup>
        <!-- 选择胶件生产类 -->
        <van-popup 
            v-model="showJjsclPicker"
            round
            position="bottom"
        >
            <van-picker 
                show-toolbar
                :columns="jjsclData"
                :confirm-button-text="$t('module.confirm')"
                :cancel-button-text="$t('module.cancel')"
                @cancel="showJjsclPicker = false"
                :default-index="jjsclDefault"
                @confirm="onConfirmJjscl" 
            />
        </van-popup>
    </div>
</template>
<script>
// getCurUserInfo
import { parseTime } from '@/utils'
import { 
    getCurUserInfo,
    saveZsInputData,
    getZsInputData,
    postZsInputData,
    agreeTmList,
    notAgreeTmlist 
} from '@api/wxjj.js'
import { GetFilterDropDownList } from "@api/wx.js";
import { Toast } from 'vant';
export default {
    data () {
        return {
            userInfo: JSON.parse(localStorage.userInfo) || {},
            form:{
                empname:"",
                bd:"",
                ed:"",
                scd:"",
                bb:"",
                worktype:"",
                jjscl:"",
            },
            showBdPicker:false,
            minDate: new Date(new Date().getFullYear() - 20, 0, 1),
            maxDate: new Date(new Date().getFullYear() + 20, 0, 31),
            showEdPicker:false,
            worksWayData:[],
            jjsclData:[],
            showWorkTypePicker:false,
            showJjsclPicker:false,
            newAutoid:0,
            dataData:[],
            bdDefault:new Date(),
            edDefault:new Date(),
            worksWayDefault:0,
            jjsclDefault:0,
            formDisable:false,
            showCheckBtn:false,
            showBtn:true
        }
    },
    created () {
        this.worksWayData = [];
        GetFilterDropDownList({
            moduleno: 816,
            username: this.userInfo.username,
            listsql: "@工作方式",
        }).then((res) => {
            if (res.data.length > 0){
                for (let i = 0; i < res.data.length; i++){
                    this.worksWayData.push(res.data[i].dicvalue);
                }
            }
        });
        this.jjsclData = [];
        GetFilterDropDownList({
            moduleno: 816,
            username: this.userInfo.username,
            listsql: "@胶件生产类",
        }).then((res) => {
            if (res.data.length > 0){
                for (let i = 0; i < res.data.length; i++){
                    this.jjsclData.push(res.data[i].dicvalue);
                }
            } 
        });
        if(this.$route.query.type == 1){
            this.formDisable = true;
            this.showCheckBtn = true;
        }
        if(this.$route.query.type == 3){
            this.formDisable = true;
            this.showBtn = false;
        }
        setTimeout(()=>{
            if(this.$route.query.autoid){
                this.newAutoid = this.$route.query.autoid;
                this.getZsInputData();
            }else{
                this.getCurUserInfo();
            }
        },300)
    },
    computed:{
        canSave (){
            if(!this.form.bd){
                return true;
            }else 
            if(!this.form.ed){
                return true;
            }else
            if(!this.form.worktype){
                return true;
            }else
            if(!this.form.jjscl){
                return true;
            }else{
                return false;
            }
        }
    },
    watch: {
        $route(to, from) {
            
        }
    },
    methods: {
        onClickLeft () {
            if(this.$route.query.autoid){
                this.$router.push('/toDoList')
            }else{
                this.$router.push('/home')
            }
        },
        getCurUserInfo () {
            getCurUserInfo({
                moduleno:816,
                username:this.userInfo.username
            }).then(res=>{
                const obj = res.data[0];
                this.form.bb = obj.bb;
                this.form.scd = obj.scd;
                this.form.empname = obj.empname;
            })
        },
        handShowBdPicker() {
            if(!this.formDisable){
                this.showBdPicker = true;
            }
        },
        onBdConfirm(v) {
            this.form.bd = parseTime(v, '{y}-{m}-{d}');
            this.showBdPicker = false;
        },
        handShowEdPicker () {
            if(!this.formDisable){
                this.showEdPicker = true;
            }
        },
        onEdConfirm(v){
            this.form.ed = parseTime(v, '{y}-{m}-{d}');
            this.showEdPicker = false;
        },
        // 获取工作方式
        getWorksWay(){
            if(!this.formDisable){
                this.showWorkTypePicker = true;
            }
        },
        onConfirmWorksWay (value) {
            this.form.worktype = value;
            this.showWorkTypePicker = false;
        },
        // 获取胶件生产类
        getJjscl(){
            if(!this.formDisable){
                this.showJjsclPicker = true;
            }
        },
        onConfirmJjscl(value){
            this.form.jjscl = value;
            this.showJjsclPicker = false;
        },
        stagingFun (type) {
            if(!this.form.bd){
                Toast.fail('开始日期不能为空！');
                return false;
            }
            if(!this.form.ed){
                Toast.fail('结束日期不能为空！');
                return false;
            }
            if(!this.form.worktype){
                Toast.fail('工作方式不能为空！');
                return false;
            }
            if(!this.form.jjscl){
                Toast.fail('胶件生产类不能为空！');
                return false;
            }
            saveZsInputData({
                autoid:this.newAutoid,
                bd:this.form.bd,
                ed:this.form.ed,
                worktype:this.form.worktype,
                jjscl:this.form.jjscl,
                remark:"",
                username:this.userInfo.username,
                data:this.dataData
            }).then(res=>{
                if(res.iserror == 0){
                    if(type == 'submit'){
                        this.saveData();
                    }else{
                        const obj = res.data[0];
                        this.newAutoid = obj.autoid;
                        this.getZsInputData();
                    }
                }
            })
        },
        getZsInputData () {
            getZsInputData({
                autoid:this.newAutoid,
                username:this.userInfo.username,
            }).then(res=>{
                const obj = res.baseinfo[0];
                this.form.bb = obj.bb;
                this.form.scd = obj.scd;
                this.form.empname = obj.empname;
                this.form.bd = obj.bd;
                this.bdDefault = new Date(obj.bd);
                this.form.ed = obj.ed;
                this.edDefault = new Date(obj.ed);
                this.form.worktype = obj.worktype;
                this.form.jjscl = obj.jjscl;
                this.dataData = res.data;
                this.worksWayDefault = this.worksWayData.indexOf(obj.worktype)
                this.jjsclDefault = this.jjsclData.indexOf(obj.jjscl)
                console.log(this.jjsclDefault,'this.jjsclDefault')
                // 暂存成功
            })
        },
        // 提交
        saveData () {
            postZsInputData({
                autoid:this.newAutoid,
                username:this.userInfo.username
            }).then(res=>{
                Toast.success('提交成功！');
                this.onClickLeft();
            })
        },
        submitFun () {
            this.stagingFun('submit')
        },
        agreeFun () {
            agreeTmList({
                moduleno:816,
                autoid:this.newAutoid,
                username:this.userInfo.username
            }).then(res=>{
                Toast.success('审批成功！');
                this.onClickLeft();
            })
        },
        notAgreeFun () {
            notAgreeTmlist({
                moduleno:816,
                autoid:this.newAutoid,
                username:this.userInfo.username
            }).then(res=>{
                Toast.success('审批成功！');
                this.onClickLeft();
            })
        }   
    }
}
</script>
<style lang="less" scoped>
.zsCheckInContainer {
    height: 100vh;
    overflow: hidden;

    .van-nav-bar {
        background: #2b8df0;

        /deep/ .van-nav-bar__left {
            .van-icon {
                color: #fff;
            }
        }

        /deep/.van-nav-bar__title {
            color: #fff;
            font-size: 36px;
            font-family: Source Han Sans CN;
        }
    }

    .content {
        overflow: auto;
        margin-top: 1.22667rem;
        height: calc(100% - 92px);
        padding-bottom: 80px;
    }
}
.footerBox{
    position: fixed;
    z-index: 999;
    bottom: 0;
    left: 0;
    height: 80px;
    background: #fff;
    display: flex;
    align-items: center;
    .flexBox{
        width: 100vw;
        flex-wrap: nowrap;
        overflow: hidden;
        height: 80%;
        display: flex;
        justify-content: space-around;
    }
}
.contentMainTable {
    width: 100%;
    overflow: hidden;
    padding: 0 0.34667rem;
    background: #fff;
    table {
        th {
            font-size: 26px;
            color: #000000;
        }
        td {
            padding: 15px 20px;
            input {
                font-size: 28px;
            }
        }
    }
}

table tr:nth-child(odd) {
  background-color: #f1f5fc;
}

table tr:nth-child(even) {
  background-color: #ffffff;
}


.h5Input {
  width: 180px;
  border: 1px solid #d2d2d2;
  outline: 0 !important;
  border-radius: 10px;
  padding-left: 8px;
}
</style>